import { css } from '@emotion/react'
import { theme } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'

import { AspectImage } from '../Image'

const FootballTeam = (props) => {
  const { name, teamLogo, stadiumText, stadiumImage } = props

  return (
    <div
      css={css`
        width: 100%;
        overflow: hidden;
        position: relative;
      `}
    >
      <AspectImage {...stadiumImage} aspectRatio="2/1" />
      <div
        css={css`
          position: absolute;
          bottom: 60px;
          right: -30px;
          background-color: ${theme.colors.surface.primary};
          width: 160px;
          height: 60px;
          transform: skewX(-30deg);
        `}
      />
      <div
        css={css`
          position: absolute;
          bottom: 0;
          background-color: ${theme.colors.surface.primary};
          width: 100%;
          padding: 16px 20px;
        `}
      >
        <Headline
          as={'h4'}
          css={css`
            margin-bottom: 0;
          `}
        >
          {name}
        </Headline>
        {stadiumText}
      </div>
      <img
        src={teamLogo.url}
        alt={teamLogo.title}
        css={css`
          position: absolute;
          bottom: 34px;
          right: 34px;
          width: 60px;
          height: 60px;
        `}
      />
    </div>
  )
}

export default FootballTeam
