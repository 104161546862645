import { graphql } from 'gatsby'

import { FlexCard } from '../Card'
import { gridMap } from '../../utils'
import FootballTeam from './FootballTeam'
import FootballMatch from './FootballMatch'

const FootballCalendar = (props) => {
  const { footballSchedulesCollection } = props
  const currentSchedule = footballSchedulesCollection.items[0]
  const matches = currentSchedule.matchesCollection.items

  const scheduleToShow = matches.map((match, i) => {
    const { date, opposingTeam, price, linkToProductPage, soldOut } = match
    return (
      <FootballMatch
        key={i}
        homeTeamLogo={currentSchedule.team.teamLogo}
        date={date}
        opposingTeam={opposingTeam}
        price={price}
        linkToProductPage={linkToProductPage}
        soldOut={soldOut}
      />
    )
  })

  return (
    <FlexCard
      width={gridMap['full-width']}
      mediaWidth={{ md: 8 }}
      contentWidth={{ md: 4 }}
      media={
        <FootballTeam
          stadiumImage={currentSchedule.team.stadiumImage}
          name={currentSchedule.team.name}
          stadiumText={currentSchedule.team.stadiumText}
          teamLogo={currentSchedule.team.teamLogo}
        />
      }
    >
      {scheduleToShow}
    </FlexCard>
  )
}

export const FootballCalendarFragment = graphql`
  fragment FootballCalendar on contentful_FootballCalendar {
    footballSchedulesCollection(limit: 1) {
      items {
        matchesCollection(limit: 12) {
          items {
            date
            price
            soldOut
            linkToProductPage {
              ctaButtonTracking
              ctaButtonName
              ctaButtonStage
              destination {
                ... on contentful_UrlSlug {
                  slug
                }
              }
            }
            opposingTeam {
              name
              teamLogo {
                title
                url
              }
            }
          }
        }
        team {
          teamLogo {
            title
            url
          }
          stadiumText
          stadiumImage {
            title
            url
          }
          name
        }
      }
    }
  }
`

export default FootballCalendar
