import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { format } from 'date-fns'
import { theme, media } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'
import { ChevronRight } from '@dfds-ui/icons/system'
import { track } from '@dfds-frontend/tracking'

import { AspectImage } from '../Image'
import { useLocaleContext } from '../LocaleContext'
import { getTranslatedMonthShort } from '../../utils'
import { useLocalizedLinkTrackingData } from '../EntryLink/LocalizedLink'

const FootballMatch = (props) => {
  const {
    homeTeamLogo,
    date,
    opposingTeam,
    price,
    linkToProductPage,
    soldOut,
  } = props
  const { t } = useTranslation()
  const { localeLower } = useLocaleContext()
  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()
  const trackingData = getLocalizedLinkTrackingData(linkToProductPage)

  return (
    <Link
      to={!soldOut && `/${localeLower}/${linkToProductPage.destination.slug}`}
      onClick={() =>
        !soldOut && linkToProductPage?.ctaButtonTracking && track(trackingData)
      }
      css={css`
        overflow: hidden;
        width: 100%;
        height: 60px;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: ${!soldOut
          ? theme.colors.text.dark.primary
          : theme.colors.text.dark.disabled};
        padding: 10px 0;

        &:visited {
          color: ${!soldOut
            ? theme.colors.text.dark.primary
            : theme.colors.text.dark.disabled};
        }
        &:hover {
          color: ${!soldOut
            ? theme.colors.text.dark.primary
            : theme.colors.text.dark.disabled};
        }

        div {
          margin: 0;
        }

        div p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 1;
        }
        svg {
          margin-left: 6px;
        }
      `}
    >
      <div
        css={css`
          width: 10%;

          ${media.lessThan('m')`
            width: 5%;
          `}
          p {
            text-align: center;
          }
        `}
      >
        <p>{getTranslatedMonthShort(format(new Date(date), 'M') - 1, t)}</p>
        <p>{format(new Date(date), 'dd')}</p>
        <p>&apos;{format(new Date(date), 'yy')}</p>
      </div>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
          width: 60%;
        `}
      >
        <AspectImage
          setWidth={30}
          setHeight={30}
          {...homeTeamLogo}
          aspectRatio="1/1"
          overflow={'visible'}
        />
        <div>{t('FOOTBALL-MATCH-VS').toLowerCase()}</div>
        <div
          css={css`
            font-weight: 700;
          `}
        >
          {opposingTeam.name}
        </div>
      </div>
      {!soldOut ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 30%;
            ${media.lessThan('m')`
              width: 35%;
            `}
          `}
        >
          <div
            css={css`
              margin-left: auto !important;
            `}
          >
            <p>{t('GENERAL-FROM')}</p>
            <p>
              <span
                css={css`
                  margin-right: 4px;
                `}
              >
                {t('GENERAL-CURRENCY')}
              </span>
              <span
                css={css`
                  font-size: 20px;
                  font-weight: 700;
                `}
              >
                {price.toFixed(2).replace(/[.,]00$/, '')}
              </span>
            </p>
          </div>
          <ChevronRight color={theme.colors.text.secondary.primary} />
        </div>
      ) : (
        <div
          css={css`
            margin-left: auto !important;
          `}
        >
          {t('GENERAL-SOLD-OUT') && t('GENERAL-SOLD-OUT').toUpperCase()}
        </div>
      )}
    </Link>
  )
}

export default FootballMatch
